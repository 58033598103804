import { Button } from "antd";
import styles from "../clientsJob.module.scss";
import { useState } from "react";
import Candidate from "../../../../redux/models/candidate";
import { EditCandidateModal } from "./editCandidateModal";

export const EditCandidateButton = ({
  disabled,
  onSuccess,
  candidateActivity,
  getCandidateUdf,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const res = await Candidate.edit(values);
      setOpen(false);
      onSuccess?.(res);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        className={styles.editBtn}
        type="link"
        disabled={disabled}
        onClick={() => setOpen(true)}
        icon={<i className="icon-edit"></i>}
      />

      {open && (
        <EditCandidateModal
          open={open}
          loading={loading}
          onCancel={() => setOpen(false)}
          onFinish={onFinish}
          candidateActivity={candidateActivity}
          getCandidateUdf={getCandidateUdf}
        />
      )}
    </>
  );
};
