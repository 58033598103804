import NetworkCall from "network/networkCall";
import Request from "network/request";
import {
  camelCaseKeys,
  camelCaseKeysRecursively,
} from "utilities/generalUtility";

export default class Candidate {
  static async getByEmail(email, loading = true) {
    const res = await NetworkCall.fetch(
      Request.getCandidateByEmail(email),
      loading,
    );
    return camelCaseKeys(res.data);
  }

  static async getActivity(id) {
    const res = await NetworkCall.fetch(Request.getCandidateActivity(id));
    return camelCaseKeysRecursively(res.data);
  }

  static async create(values) {
    const res = await NetworkCall.fetch(Request.createJobCandidate(values));
    return camelCaseKeysRecursively(res.data);
  }

  static async getAll() {
    const res = await NetworkCall.fetch(Request.getAllCandidates());
    return res.data.map((dt) => camelCaseKeys(dt));
  }

  static async getByClientId(body) {
    const res = await NetworkCall.fetch(Request.getCandidatesByClientIds(body));
    return camelCaseKeysRecursively(res.data);
  }

  static async getByJobId(body) {
    const res = await NetworkCall.fetch(Request.getCandidatesByJobId(body));
    return camelCaseKeysRecursively(res.data);
  }

  static async getJobRecruiterByJobId(id, loading = true) {
    const res = await NetworkCall.fetch(
      Request.getJobRecruiterByJobId(id),
      loading,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getCandidateDetail(id) {
    const res = await NetworkCall.fetch(Request.getCandidateDetail(id));
    return camelCaseKeysRecursively(res.data);
  }

  static async addToBlacklist(data) {
    const res = await NetworkCall.fetch(Request.addToBlacklist(data));
    return camelCaseKeys(res.data);
  }

  static async removeToBlacklist(data) {
    const res = await NetworkCall.fetch(Request.removeToBlacklist(data));
    return camelCaseKeys(res.data);
  }

  static async edit(values) {
    const res = await NetworkCall.fetch(Request.editJobCandidate(values));
    return camelCaseKeysRecursively(res.data);
  }

  static async changeStatus(values) {
    const res = await NetworkCall.fetch(Request.changeCandidateStatus(values));
    return camelCaseKeysRecursively(res.data);
  }

  static async addComment(values) {
    const res = await NetworkCall.fetch(Request.addCommentOnCandidate(values));
    return camelCaseKeysRecursively(res.data);
  }

  static async getComments(id) {
    const res = await NetworkCall.fetch(Request.getCandidateComments(id));
    return camelCaseKeysRecursively(res.data);
  }

  static async getLogs(id) {
    const res = await NetworkCall.fetch(Request.getCandidateLogs(id));
    return camelCaseKeysRecursively(res.data);
  }

  static async getCandidateBillings(id) {
    const res = await NetworkCall.fetch(Request.getCandidateBillingsIssues(id));
    return camelCaseKeysRecursively(res.data);
  }

  static async uploadCV(file, folder) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("folderName", folder);
    const res = await NetworkCall.fetch(Request.uploadFile(formData), false);
    return res.data;
  }

  static async editCandidateDetails(body) {
    const res = await NetworkCall.fetch(Request.editCandidateDetailsById(body));
    return camelCaseKeysRecursively(res.data);
  }

  static async calculateBilling(candidateJobEntry, date) {
    const res = await NetworkCall.fetch(
      Request.calculateBilling(candidateJobEntry, date),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async sortCandidateByStatus(statusId, jobId, subStatusId) {
    const res = await NetworkCall.fetch(
      Request.sortCandidateByStatus(statusId, jobId, subStatusId),
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async editOfferedSalary(payload) {
    const res = await NetworkCall.fetch(
      Request.editCandidateOfferedSalary(payload),
    );
    return camelCaseKeys(res.data);
  }

  static async deleteCV(filePath) {
    const res = await NetworkCall.fetch(Request.deleteCV(filePath));
    return res;
  }

  static async suggestAnEmail(jobId) {
    const res = await NetworkCall.fetch(Request.suggestAnEmail(jobId));
    return camelCaseKeys(res.data);
  }

  static async deleteCandiate(candidateJobEntryId) {
    const res = await NetworkCall.fetch(
      Request.deleteCandiate(candidateJobEntryId),
    );
    return res;
  }

  static async transferCandiateJob(body, loader = true) {
    const res = await NetworkCall.fetch(
      Request.transferCandiateJob(body),
      loader,
    );
    return res;
  }

  static async getCandidateBilling(candidateJobEntryId, loader = true) {
    const res = await NetworkCall.fetch(
      Request.getCandidateBillings(candidateJobEntryId),
      loader,
    );
    return camelCaseKeysRecursively(res.data);
  }

  static async getByClassId(id) {
    const res = await NetworkCall.fetch(Request.getCandidatesByClassId(id));
    return camelCaseKeysRecursively(res.data);
  }

  static async assignCandidate(body) {
    const res = await NetworkCall.fetch(Request.assignCandidateClass(body));
    return camelCaseKeysRecursively(res.data);
  }

  static async detachCandidate(body) {
    const res = await NetworkCall.fetch(Request.detachCandidateClass(body));
    return camelCaseKeysRecursively(res.data);
  }

  static async getClientClassManager(body) {
    const res = await NetworkCall.fetch(Request.getClientClassManager(body));
    return camelCaseKeysRecursively(res.data);
  }

  static async changeCandidateStatusInBulk(body) {
    const res = await NetworkCall.fetch(
      Request.changeCandidateStatusInBulk(body),
    );
    return camelCaseKeysRecursively(res.data);
  }

  // v2

  static v2 = {
    get: async (params, useLoading = true) => {
      const res = await NetworkCall.fetch(
        Request.v2.getCandidates(params),
        useLoading,
      );
      return { ...res, data: camelCaseKeysRecursively(res.data) };
    },

    merge: async (data) => {
      const res = await NetworkCall.fetch(Request.v2.mergeCandidates(data));
      return { ...res, data: camelCaseKeys(res.data) };
    },

    editEngagementNotes: async (jobId, candidateId, data) => {
      const res = await NetworkCall.fetch(
        Request.v2.editCandidateJobEntry(jobId, candidateId, data),
      );
      return camelCaseKeys(res.data);
    },
  };
}
