import { useSelector } from "react-redux";
import { selectAccessibleClientsBySelection } from "../../../redux/slices/userSlice";
import { Card, Col, Divider, Row, Select, Typography } from "antd";
import { TableauReport } from "./tableauReport/tableauReport";
import { ParseNumber, useQueryState } from "../../../hooks/useQueryState";
import { useEffect } from "react";

const { Paragraph, Title } = Typography;

export const TableauReportContainer = ({ type }) => {
  const clients = useSelector(selectAccessibleClientsBySelection);

  const [clientId, setClientId] = useQueryState("clientId", ParseNumber);

  const options = clients.map((c) => ({ value: c.id, label: c.name }));

  useEffect(() => {
    if (clientId && !clients.some((c) => c.id === clientId)) {
      setClientId(null);
    }
  }, [clients]);

  const renderContent = () => {
    if (!clientId) {
      return (
        <Row justify="center" style={{ marginTop: 24 }}>
          <Col style={{ textAlign: "center" }}>
            <Title level={4}>Select a Client to View Report</Title>
            <Paragraph>
              Please select a client from the dropdown list to view the
              corresponding report.
            </Paragraph>
          </Col>
        </Row>
      );
    }

    return <TableauReport type={type} clientId={clientId} />;
  };

  return (
    <Card
      bodyStyle={{
        padding: 16,
        minHeight: "850px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Row>
        <Col style={{ width: "100%", maxWidth: 230, minWidth: 100 }}>
          <Select
            style={{ width: "100%" }}
            placeholder={"Select Client"}
            options={options}
            onChange={setClientId}
            value={clientId}
            showSearch
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Col>
      </Row>
      <Divider style={{ margin: "16px 0" }} />
      {renderContent()}
    </Card>
  );
};
