import { Form, Modal } from "antd";
import styles from "../clientsJob.module.scss";
import AddJobCandidate from "../../../candidates/addJobCandidate";
import { useState } from "react";

export const EditCandidateModal = ({
  open,
  onCancel,
  onFinish,
  loading,
  candidateActivity,
  getCandidateUdf,
}) => {
  const [form] = Form.useForm();

  const [isDataLoading, setIsDataLoading] = useState(true);

  return (
    <Modal
      title={`Edit Candidate`}
      className={`${styles.addNewCandidateModal} s2-theme-style modal-overflow-auto`}
      open={open}
      width={632}
      centered
      destroyOnClose
      onCancel={onCancel}
      closeIcon={<i className={"icon-closeable " + styles.closeAble}></i>}
      okText="Submit"
      onOk={form.submit}
      okButtonProps={{ loading: loading, disabled: isDataLoading }}
    >
      <AddJobCandidate
        styles={styles}
        form={form}
        getCandidateUdf={getCandidateUdf}
        onFinish={onFinish}
        candidateActivity={candidateActivity}
        isDataLoading={isDataLoading}
        setIsDataLoading={setIsDataLoading}
      />
    </Modal>
  );
};
